import React from 'react'

const Daddy = ({ direction, weight }) => {
  return (
    <div className={`daddyname ${direction}`}>
      {weight === 'start' ? (
        <>
          <div>
            <strong>DA</strong>DDY
          </div>
          <div>
            <strong>DA</strong>DDY
          </div>
          <div>
            <strong>DA</strong>DDY
          </div>
          <div>
            <strong>DA</strong>DDY
          </div>
        </>
      ) : (
        <>
          <div>
            DA<strong>DDY</strong>
          </div>
          <div>
            DA<strong>DDY</strong>
          </div>
          <div>
            DA<strong>DDY</strong>
          </div>
          <div>
            DA<strong>DDY</strong>
          </div>
        </>
      )}
    </div>
  )
}

export default Daddy
