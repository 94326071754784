import React, { useState, useCallback } from 'react'
import useEventListener from '@use-it/event-listener'

const Cursor = () => {
  const [isVisible, setIsVisible] = useState(true)
  const [coords, setCoords] = useState({ x: 0, y: 0 })

  const onMouseEnter = useCallback(() => setIsVisible(true), [])
  const onMouseLeave = useCallback(() => setIsVisible(false), [])

  const onMouseMove = useCallback(({ clientX, clientY }) => {
    setCoords({ x: clientX, y: clientY })
  }, [])

  if (typeof document !== `undefined`) {
    useEventListener('mousemove', onMouseMove, document)
    useEventListener('mouseenter', onMouseEnter, document)
    useEventListener('mouseleave', onMouseLeave, document)
  }

  return (
    <div
      className={`cursor${isVisible ? ' is-visible' : ''}`}
      style={{ top: coords.y, left: coords.x }}
    >
      yes
    </div>
  )
}

export default Cursor
