import React, { useEffect } from 'react'
import axios from 'axios'

// site layout
import SEO from '../components/scaffolding/seo'
import Cursor from '../components/cursor'
import Daddy from '../components/daddy'

const indexPage = ({ location }) => {
  const giphy = {
    baseURL: 'https://api.giphy.com/v1/gifs/',
    apiKey: '0UTRbFtkMxAplrohufYco5IY74U8hOes',
    tag: 'daddy',
    type: 'random',
    rating: 'R'
  }

  let giphyURL = encodeURI(
    giphy.baseURL +
      giphy.type +
      '?api_key=' +
      giphy.apiKey +
      '&tag=' +
      giphy.tag +
      '&rating=' +
      giphy.rating
  )

  const newGif = e => {
    const xOffset = e.nativeEvent.offsetX
    const yOffset = e.nativeEvent.offsetY

    axios
      .get(giphyURL)
      .then(res => {
        var dad = new Image()
        dad.onload = () => {
          document.body.appendChild(dad)
        }
        dad.classList.add('dad')
        dad.style.top = yOffset + 'px'
        dad.style.left = xOffset + 'px'
        dad.src = res.data.data.image_original_url
      })
      .catch(() => {
        console.log('error!')
      })
  }

  return (
    <>
      <SEO />
      <Cursor />
      <main role="main" id="content" onClick={e => newGif(e)}>
        <Daddy direction="left" weight="start" />
        <Daddy direction="right" weight="end" />
      </main>
    </>
  )
}

export default indexPage
